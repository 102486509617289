<template>
  <div id="app">
    <page />
  </div>
</template>

<script>
import Page from './components/Page.vue'
import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  components: {
    Page
  }
})
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  font-family: 'Noto Sans JP', '游ゴシック体',YuGothic,'游ゴシック',Yu Gothic,-system-ui,-apple-system,Hiragino Sans,Yu Gothic UI,Segoe UI,Meiryo,sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.container {
  max-width: 768px;
  margin: 0 auto;
  padding: 0 16px;
}

footer {
  .info {
    h2 {
      text-align: center;

      @media all and (max-width: 800px) {
        font-size: 16px;
        margin-bottom: .2rem;
      }
    }

    table {
      margin: 0 auto;
      @media all and (max-width: 800px) {
        font-size: 14px;
      }
    }

    th {
      white-space: nowrap;
      text-align-last: justify;
      vertical-align: top;
      font-weight: normal;

      &::after {
        content: ':';
        position: absolute;
      }
    }

    td {
      padding-left: .4rem;
    }

    .banner {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }
  }

  .copyright {
    font-size: 12px;
    text-align: center;
    padding-top: 140px;
    position: relative;
    @media all and (max-width: 800px) {
      padding-top: 80px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 140px;
      width: 100%;
      background: url('/demo/assets/images/head-bg.jpg');
      background-size: 100% 60%;
      top: 0;

      @media all and (max-width: 800px) {
        height: 80px;
        background-size: 100% 60%;
      }
    }
  }
}

.modal-content-wrapper {

}

.modal-backdrop {
  background-color: transparent;
}

.modal-content {
  background: transparent;
  color: #333;
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #ffffff;
}

.basic-modal {
  > div {
    text-align: center;
    img {
      max-width: min(90vw, 600px);
      width: 100%;
      height: auto;
    }
  }
}
</style>
