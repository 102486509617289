<template>
  <div class="controller">
    <div class="stamp-container"><stamp /></div>
    <div class="controller-range">
      <div>
        <div>回転</div>
        <div><input type="range" name="range" min="-90" max="90" v-model.number="CopyStand.angle" :disabled="!isControllable"></div>
      </div>
      <div>
        <div>ズーム</div>
        <div><input type="range" name="range" value="0" min="0.87" max="3" step="0.01" v-model.number="CopyStand.zoom" :disabled="!isControllable"></div>
      </div>
    </div>
    <div class="controller-button">
      <div><label for="loadImage" :class="{ disabled: !isUserImageSelectable }">合成する写真を選ぶ<input type="file" @change="loadImage" accept="image/*" id="loadImage" :disabled="!isUserImageSelectable"></label></div>
      <div><button @click="openModal" class="confirm" :disabled="!isControllable">決定</button></div>
    </div>
    <modal name="confirm" @closed="onClose">
      <div class="basic-modal">
        <div class="content">
          <div class="modal-header">
            <img src="/demo/assets/images/logo.png" alt="">
          </div>
          <div class="result-image">
            <div>
              <img :src="trimedImage.dataURI">
            </div>
          </div>
        </div>
        <div class="modal-actions">
          <button class="button back-button" type="button" @click="closeModal" :class="{'for-ios': UA.isIOS()}"><img src="/demo/assets/images/button-back.png" alt=""></button>
          <span v-if="UA.isIOS()" class="dl-button"><img src="/demo/assets/images/button-confirmed-1.png" alt=""></span>
          <a v-if="!UA.isIOS()" :href="trimedImage.dataURI" :download="fileName" target="_blank" class="dl-button"><img src="/demo/assets/images/button-confirmed-2.png" alt=""></a>
        </div>
        <div class="sns-links">
          <div>
            <img src="/demo/assets/images/dummy-buttons.png" alt="">
          </div>
        </div>
        <div class="modal-footer">
          <img src="/demo/assets/images/modal-dummy-footer.png" alt="">
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref, watch } from '@vue/composition-api'
import { injectCopyStand, download, getTrimData } from '../compositions/useCopyStand'
import { useUA } from '../compositions/useUA'
import Stamp from './Stamp'

export default defineComponent({
  setup() {
    const CopyStand = injectCopyStand()
    const UA = useUA()

    const isControllable = computed(() => {
      return (CopyStand.userImage)
    })

    const isUserImageSelectable = computed(() => {
      return (CopyStand.selectedFrame.label)
    })

    const trimedImage = reactive({
      dataURI: ''
    })

    const fileName = ref(getFileName())

    function getFileName() {
      if (!CopyStand.selectedFrame) {
        return 'filename'
      }
      const cname = 'Gocha_合成結果'
      const name = CopyStand.selectedFrame.jpname
      const now = new Date()
      const datetime = `${now.getFullYear()}${now.getMonth() + 1}${now.getDate()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}`
      return `${cname}_${name}_${datetime}.jpg`
    }

    watch(() => trimedImage.dataURI, () => {
      fileName.value = getFileName()
    })

    return {
      CopyStand,
      UA,
      loadImage(e) {
        const files = e.target.files || e.dataTransfer.files
        if (!files.length) {
          return
        }
        const file = files[0]
        const reader = new FileReader()
        reader.onload = f => {
          const data = f.target.result
          CopyStand.userImage = data
        }
        reader.readAsDataURL(file)
      },
      openModal() {
        const stamp = !CopyStand.stamp ? '0' : CopyStand.stamp.match(/\/stamp-(\d+)\.png$/)[1]
        // eslint-disable-next-line no-undef
        gtag(
          'config',
          // eslint-disable-next-line no-undef
          tid,
          {
            page_title: `Confirmed ${CopyStand.selectedFrame.jpname} | Goshaデモンストレーション`,
            page_path: `/attraction/frame/${CopyStand.selectedFrame.jpname}/st-${stamp}`
          }
        )
        trimedImage.dataURI = getTrimData()
        this.$modal.push('confirm')
      },
      closeModal() {
        this.$modal.pop()
      },
      onClose() {
        // eslint-disable-next-line no-undef
        gtag(
          'config',
          // eslint-disable-next-line no-undef
          tid,
          {
            page_title: 'Attraction | Goshaデモンストレーション',
            page_path: '/attraction'
          }
        )
      },
      download,
      isControllable,
      isUserImageSelectable,
      trimedImage,
      fileName
    }
  },
  components: {
    Stamp
  }
})
</script>

<style lang="scss" scoped>
$key-color: #EBBC20;
$key-color-light: #333333;
$key-color-lighter: #e9eaea;
$button-bg-color: #ffffff;
$button-text-color: #333;
$button-border-color: #333;
$button-border-size: 2px;
$bg-dot-base-color: str-replace(#{$key-color-light}, '#', '');
$bg-dot-circle-color: str-replace(#{$key-color}, '#', '');

.controller {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  padding: 1rem 0 0;
  background: $key-color-lighter;
  border-radius: 1rem;
  justify-content: center;
  flex-wrap: wrap;

  .stamp-container {
    width: 90%;
    padding: 0;
    @media all and (max-width: 767px) {
      width: 95%;
    }
  }

  > div {
    padding: 1rem;
    @media all and (max-width: 767px) {
      padding: .8rem 0;
    }

    &.controller-range {
      padding-right: 0;
    }
  }
  @media all and (max-width: 767px) {
    padding: 1rem .5rem 0;
    border-radius: 0;
    justify-content: space-evenly;
  }

  .stamp {
    max-width: 720px;
    margin: 0 auto;

    @media all and (max-width: 767px) {
      width: 100%;
      margin: 0;
    }
  }
}

label {
  cursor: pointer;
  display: inline-block;
  padding: .8rem 0;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  width: 10rem;
  // border: 2px solid #333;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  background: $button-bg-color;
  color: $button-text-color;
  border: $button-border-size solid $button-border-color;
  transition: .8s;

  @media all and (max-width: 767px) {
    font-size: 11px;
    width: 7rem;
  }

  &.disabled {
    background:rgba(88, 88, 88, 0.8);
    transition: .8s;
  }

  input {
    display: none;
  }
}

.confirm {
  appearance: none;
  display: inline-block;
  padding: .8rem 0;
  width: 10rem;
  border: none;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  color: $button-text-color;
  cursor: pointer;
  background: $button-bg-color;
  border: $button-border-size solid $button-border-color;
  transition: .8s;

  @media all and (max-width: 767px) {
    font-size: 10px;
    width: 7rem;
  }

  &:disabled {
    background: rgba(88, 88, 88, 0.8);
    transition: .8s;
  }
}

.controller-range {
  @media all and (max-width: 767px) {
    width: 50%;
  }
  > div {
    width: 10rem;
    margin: 0 auto;
    text-align: center;
    color: rgba(33, 33, 33, 0.8);
    font-weight: 600;
    margin-bottom: 1rem;

    @media all and (max-width: 767px) {
      width: 100%;
    }
  }
}

.controller-button {
  > div {
    text-align: center;
  }
}

.dl-button {
  display: inline-block;
  color: #555555;
  text-decoration: none;
  background: #ffffff;
  padding: 0;
  border-radius: .5rem;
  font-size: 14px;
  margin: 0 1rem 0 1rem;
  font-weight: 600;
  width: 10rem;
}

.back-button {
  display: inline-block;
  color: #555555;
  text-decoration: none;
  background: #ffffff;
  padding: 0;
  border-radius: .5rem;
  border: none;
  font-size: 14px;
  margin: 0 1rem 0 0;
  font-weight: 600;
  cursor: pointer;
  width: 10rem;

  &.for-ios {
    width: 10rem;
    margin-left: 1rem;
  }
}

.modal-actions {
  display: flex;
  justify-content: center;
  margin: 1rem auto;
}

.basic-modal {
  .modal-header {
    padding: 3rem 2rem 1rem;
  }
  .content {
    background: url('/demo/assets/images/head-bg.jpg');
    background-size: 100% 10%;
  }

  .message {
    width: 100%;
  }

  p {
    max-width: 24rem;
    font-size: 14px;
    margin: 0 auto;
    text-align: justify;

    &.for-ios {
      text-align: center;
    }
  }

  .result-image {
    padding-bottom: 2.5rem;
    &> div {
      box-shadow: 15px 15px 25px 0px #000;
      position: relative;
      max-width: min(90vw, 600px);
      width: 100%;
      margin: 0 auto;
      @media all and (max-width: 767px) {
        box-shadow: 10px 10px 12px 0px #333;
      }

      &::before {
        content: '';
        position: absolute;
        width: 107%;
        height: 107%;
        background: url(/demo/assets/images/result-frame.png);
        background-size: 100%;
        top: -3.6%;
        left: -4.3%;
        pointer-events: none;
      }
    }
  }

  .sns-links {
    max-width: min(80vw, 420px);
    display: flex;
    margin: 0 auto;
  }

  .modal-footer {
    margin-top: 2rem;
    width: 100%;
    background: #9FA0A0;

    img {
      @media all and (max-width: 767px) {
        max-width: 100vw;
      }
    }
  }
}

input[type=range] {
  appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}

input[type=range] {
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 7px;
    cursor: pointer;
    background: $key-color-light;
    border-radius: 14px;
    transition: .8s;
  }

  &::-webkit-slider-thumb {
    border: 3px solid #ffffff;
    height: 25px;
    width: 25px;
    border-radius: 40px;
    background: $key-color;
    cursor: pointer;
    appearance: none;
    margin-top: -10.5px;
    opacity: 1;
    transition: .8s;
  }

  &::-moz-range-track {
    width: 100%;
    height: 7px;
    cursor: pointer;
    background: $key-color-light;
    border-radius: 14px;
  }

  &::-moz-range-thumb {
    border: 3px solid #ffffff;
    height: 25px;
    width: 25px;
    border-radius: 40px;
    background: $key-color;
    cursor: pointer;
  }

  &::-ms-track {
    width: 100%;
    height: 7px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-lower, &::-ms-fill-upper {
    background: #000000;
    border-radius: 28px;
  }

  &::-ms-thumb {
    border: 3px solid #ffffff;
    height: 25px;
    width: 25px;
    border-radius: 40px;
    background: #7D7D7D;
    cursor: pointer;
  }
}

input[type=range]:disabled {
  &::-webkit-slider-runnable-track {
    background: #7D7D7D;
    transition: .8s;
  }

  &::-webkit-slider-thumb {
    background: #7D7D7D;
    opacity: 0;
    transition: .8s;
  }

  &::-moz-range-track {
    background: #7D7D7D;
  }

  &::-moz-range-thumb {
    background: #7D7D7D;
    opacity: 0;
  }
}

input[type=range]:focus {
  appearance: none;
  &::-webkit-slider-runnable-track {
    background: darken($key-color, 0.4);
    transition: .8s;
  }

  &::-ms-fill-lower, &::-ms-fill-upper {
    background: #000000;
  }
}
</style>
