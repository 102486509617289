<template>
  <section class="head">
    <div class="container">
      <h1><img src="/demo/assets/images/logo.png" alt="Gosha"></h1>
    </div>
  </section>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
})
</script>

<style lang="scss" scoped>
.head {
  background: url('/demo/assets/images/head-bg.jpg');
  background-size: 100% 30%;
}

h1 {
  margin: 0;
  text-align: center;
  padding-top: min(10vw, 60px);
  position: relative;

  img {
    width: min(70vw, 640px);
  }

  &::before {
    content: '';
    background: url('/demo/assets/images/head-parts-1.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 50%;
    height: 225px;
    display: block;
    position: absolute;
    top: -4vw;
    left: -10%;
  }

  &::after {
    content: '';
    background: url('/demo/assets/images/head-parts-2.png');
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 180px;
    width: 20vw;
    height: 110%;
    display: block;
    position: absolute;
    bottom: 0;
    right: -15%;

    @media all and (max-width: 960px) {
      right: 0;
    }
  }
}
</style>
