<template>
  <div class="frames">
    <div class="frame-list">
      <div
        v-for="frame in frames"
        :key="frame.frame"
        class="frame"
        @click="setFrame(frame)">
        <div>
          <img :src="frame.frame" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import { injectCopyStand } from '../compositions/useCopyStand'

export default defineComponent({
  setup(_, { root }) {
    const frames = [
      {
        label: '1',
        photo: '/demo/assets/images/frames/1_photo.jpg',
        frame: '/demo/assets/images/frames/1.png',
        jpname: 'アメフト',
        color: '#ececec'
      },
      {
        label: '2',
        photo: '/demo/assets/images/frames/2_photo.jpg',
        frame: '/demo/assets/images/frames/2.png',
        jpname: 'サッカー',
        color: '#000000'
      },
      {
        label: '3',
        photo: '/demo/assets/images/frames/3_photo.jpg',
        frame: '/demo/assets/images/frames/3.png',
        jpname: '野球',
        color: '#c5301e'
      },
      {
        label: '4',
        photo: '/demo/assets/images/frames/4_photo.jpg',
        frame: '/demo/assets/images/frames/4.png',
        jpname: '競馬',
        color: '#104293'
      },
      {
        label: '5',
        photo: '/demo/assets/images/frames/5_photo.jpg',
        frame: '/demo/assets/images/frames/5.png',
        jpname: 'アニマル',
        color: '#f1cd1f'
      },
      {
        label: '6',
        photo: '/demo/assets/images/frames/6_photo.jpg',
        frame: '/demo/assets/images/frames/6.png',
        jpname: 'ヒーロー',
        color: '#008b44'
      },
      {
        label: '7',
        photo: '/demo/assets/images/frames/7_photo.jpg',
        frame: '/demo/assets/images/frames/7.png',
        jpname: 'コミックフレーム',
        color: '#ececec'
      },
      {
        label: '8',
        photo: '/demo/assets/images/frames/8_photo.jpg',
        frame: '/demo/assets/images/frames/8.png',
        jpname: 'アニメ',
        color: '#000000'
      }
    ]

    const CopyStand = injectCopyStand()

    return {
      frames,
      CopyStand,
      setFrame(frame) {
        CopyStand.selectedFrame = frame
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.frame-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .frame {
    width: 24%;
    cursor: pointer;

    img {
      border-radius: 5px;
      border: 1px solid gray;
    }
  }
}
</style>
