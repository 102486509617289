<template>
  <div>
    <g-head />
    <div class="container">
      <div class="introduction">
        <p>「Gosha(ゴーシャ)」とは、<br><b>アプリのダウンロード不要！</b>スマホや PC の WEB ブラウザを活用した、誰でも簡単に合成写真を作れる体験アトラクションです。</p>
        <p>WEBアプリなので、既存のWEBサイトに組み込んだり、ランディングページのコンテンツに。アイデア次第で SNS連携やキャンペーンのアトラクションとして活用できます。</p>
      </div>
    </div>
    <section>
      <div class="heading">
        <div class="container">
          <h2 class="ic">デモをお試しください</h2>
        </div>
      </div>
      <div class="steps">
        <div class="container">
          <dl>
            <dt>step1</dt>
            <dd>好きなフレームを選択<br><span class="note">(フレームの数は自由に設定可能)</span></dd>
            <dt>step2</dt>
            <dd>合成する写真をカメラで撮影<br>またはフォルダから選択</dd>
            <dt>step3</dt>
            <dd>写真の回転・ズーム・位置調整を行います</dd>
            <dt>step4</dt>
            <dd>決定ボタンで合成します</dd>
          </dl>
        </div>
      </div>
      <div class="container">
        <attraction />
        <span class="notice">＊画面は開発中のものです。実際はイベントに合わせたデザインに制作いたします。</span>
      </div>
      <div class="button">
        <a href="https://forms.gle/fp7T9ZvTabGyCL518"><img src="/demo/assets/images/button-inquiry.png" alt="お問合わせ"></a>
      </div>
      <div class="button">
        <a href="/demo/assets/pdf/Gosha_leaf2102.pdf"><img src="/demo/assets/images/button-download.png" alt="資料ダウンロード"></a>
      </div>
      <hr>
    </section>

    <section class="characteristic container">
      <div class="heading">
        <h2 class="label"><img src="/demo/assets/images/h-characteristic.png" alt="特徴"></h2>
      </div>
      <ul>
        <li>簡単操作で、幅広い年齢層が楽しめます</li>
        <li>新たな見込み客の獲得</li>
        <li>1日～通年まで、継続した運用が可能</li>
        <li>アプリ不要でユーザーの参加率が高い</li>
        <li>テンプレート利用で導入コストを抑えられます(制作期間：最短で約2週間～)</li>
        <li>各種プラットフォーム(android、iOS、PC)の最新WEBブラウザーに対応。</li>
        <li>Google アナリティクスでアクセス解析が可能(閲覧権限を付与)</li>
      </ul>
    </section>

    <section class="option container">
      <div class="heading">
        <h2 class="label"><img src="/demo/assets/images/h-option.png" alt="オプション"></h2>
      </div>
      <ul>
        <li>フレームの素材撮影、デザイン制作</li>
        <li>あと乗せスタンプ</li>
        <li>合成写真をその場でプリントする操作端末とプリンターの販売又はレンタル</li>
        <li>ご要望の機能を追加開発いたします<br>  ※オプションの詳細はお問合わせください。</li>
      </ul>
    </section>

    <section class="example container">
      <div class="heading">
        <h2 class="label"><img src="/demo/assets/images/h-example.png" alt="導入例"></h2>
      </div>
      <div class="image">
        <img src="/demo/assets/images/example.jpg" alt="導入例イメージ">
      </div>
      <p>
        WEB でもリアルイベントでもアイデア次第で<br>キャンペーン効果に付加価値を与えられます。
      </p>
    </section>

    <section class="price container">
      <div class="heading">
        <h2 class="label"><img src="/demo/assets/images/h-price.png" alt="導入例"></h2>
      </div>
      <div class="price-detail">
        <h3>【基本料金】</h3>
        <ul>
          <li>企画ディレクション費</li>
          <li>WEBページ制作費</li>
          <li>合成アトラクション製作費</li>
        </ul>
        <hr>
        <span class="start-at">
          初期費用一式<span>29</span>万円～*
        </span>
        <h3>【オプション】</h3>
        <ul class="option">
          <li>合成デザイン製作費</li>
          <li>オプション機能追加、新規開発</li>
          <li>サーバー・ドメイン取得代行、初期設定</li>
          <li>サーバー使用費</li>
        </ul>
      </div>
      <div class="notes">
        <span>＊テンプレート利用時の参考価格(税別)です。</span>
      </div>
      <span class="fcm">企画内容に応じて迅速にお見積り致します。</span>
    </section>

    <div class="button">
      <a href="https://forms.gle/fp7T9ZvTabGyCL518"><img src="/demo/assets/images/button-inquiry.png" alt="お問合わせ"></a>
    </div>
    <div class="button">
      <a href="/demo/assets/pdf/Gosha_leaf2102.pdf"><img src="/demo/assets/images/button-download.png" alt="資料ダウンロード"></a>
    </div>

    <section class="message container">
      <h2>ごあいさつ</h2>
      <p>
        with コロナに順応する形で、商品やサービスの販促活動、各種エンターテイメントに関連するイベントがリアルからWEBに大きくシフトしています。シエンアートは、写真館運営と出張合成写真撮影サービスを展開しており、幅広い分野のイベントで販促活動のお手伝いをしてきました。その実績ノウハウを活かし、リアルイベント開催が難しい今だからこそ、WEBアプリを活用したフォトエンターテイメントをご提案します。
      </p>
    </section>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import GHead from './mainPart/GHead'
import Attraction from './Attraction'

export default defineComponent({
  components: {
    GHead,
    Attraction
  }
})
</script>

<style lang="scss" scoped>
  .heading {
    text-align: center;
    margin-top: 4rem;
    h2 {
      margin-bottom: .5rem;
      img {
        max-height: 30px;
      }
    }

    .ic {
      background: url(/demo/assets/images/headbg.png);
      padding: .5em;
      color: #E55814;
    }
  }

  .notice {
    font-size: 14px;
    color: red;
    margin: .5rem auto 2rem;
    display: block;
    text-align: center;
  }

  .introduction {
    p {
      text-align: justify;

      b {
        white-space: nowrap;
        color: #E60012;
      }

      &:first-child {
        color: #172A86;
        font-weight: bold;
        font-size: 24px;
      }
    }
  }

  .steps {
    dl {
      display: flex;
      flex-direction: column;
      align-items: center;

      dt, dd {
        margin: 0;
        text-align: center;
        line-height: 1.2;
        font-family: "Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
      }

      dt {
        color: #3FAE6C;
        font-size: 1.4rem;
      }

      dd {
        font-size: 1.5rem;
        padding-bottom: 1.5rem;
      }

      .note {
        font-size: 1.3rem;
        font-family: "Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
      }
    }
  }

  .sp-frame {
    max-width: 600px;
    min-height: 600px;
    position: relative;
    margin-bottom: 10rem;
  }

  .sp-frame-in {
    position: absolute;
    width: 100%;
    height: calc(100% + 160px);
    padding: 0;
    left: 0;
    top: -16px;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 200px;
      background: url(/demo/assets/images/sp-frame-ih.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 150px;
      background: url(/demo/assets/images/sp-frame-if.png);
      background-repeat: no-repeat;
      background-size: 100% auto;
      bottom: 0;
    }
  }

  .sp-frame-inner {
    margin: 0px 30px;
    padding-top: 70px;
    @media all and (max-width: 600px) {
      padding-top: 50px;
    }
  }

  .button {
    display: block;
    max-width: 260px;
    margin: 0 auto;

    &~.button {
      margin-top: .5rem;
    }
  }

  hr {
    background: url(/demo/assets/images/hr.png);
    border: none;
    height: 15px;
    background-size: contain;
    margin: 2rem auto;
    max-width: 800px;

    @media all and (max-width: 800px) {
      margin: 2rem 1.5rem;
    }
  }

  ul {
    padding-left: 15px;
  }
  .example {
    p {
      text-align: center;
    }
  }

  .price {
    background: url(/demo/assets/images/bg-2.jpg);
    text-align: center;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;

    .heading {
      padding-top: 1.5rem;
    }

    .start-at {
      font-size: 24px;
      > span {
        font-size: 28px;
        margin: 0 .5rem;
        font-weight: bold;
      }

      & + h3 {
        border-top: 1px solid black;
        margin-top: .8rem;
        padding-top: .5rem;
      }
    }

    .notes {
      margin-top: .5rem;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;
    }
  }

  .price-detail {
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    margin: 0 auto 0.5rem;
    max-width: 400px;
    h3 {
      margin: 0.5rem 0 0;
    }

    ul {
      list-style: none;
      margin: .5rem auto;
      font-size: 18px;

      &.option {
        li {
          margin-bottom: 0;
          &::before {
            display: none;
          }
        }
      }
    }

    hr {
      background: none;
      border-bottom: 1px dashed #333;
      width: 70%;
      margin: .5rem auto;
      height: 1px;
    }
  }

  .fcm {
    font-size: 1.6rem;
    font-family: "Times New Roman", "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
    color: red;
    font-weight: bold;
  }

  .message {
    position: relative;
    padding: 15px 0;
    padding-top: 15px;
    @media all and (max-width: 800px) {
      margin: 2rem 1.5rem;
    }

    &::before {
      content: '';
      background: url(/demo/assets/images/message-border.png);
      background-size: contain;
      position: absolute;
      width: 100%;
      height: 15px;
      background-repeat: repeat-x;
    }

    &::after {
      content: '';
      background: url(/demo/assets/images/message-border.png);
      background-size: contain;
      position: absolute;
      width: 100%;
      height: 15px;
      transform: scale(1, -1);
      bottom: 0;
      background-repeat: repeat-x;
    }

    h2 {
      text-align: center;
      @media all and (max-width: 800px) {
        font-size: 18px;
        margin-top: 1.2rem;
        margin-bottom: .5rem;
      }
    }

    p {
      padding: 0 1rem;
      @media all and (max-width: 800px) {
        margin-top: .5rem;
        padding: 0 .2rem;
      }
    }
  }

  .characteristic, .merit ,.option {
    ul {
      list-style: none;
    }
    li {
      margin-bottom: .4rem;
    }
    li:before {
      content: '';
      width: 12px;
      height: 12px;
      display: inline-block;
      background-size: contain;
      margin-right: .5rem;
    }
    li:nth-child(1) {
      &::before {
        background-image: url(/demo/assets/images/list-1.png);
      }
    }

    li:nth-child(2) {
      &::before {
        background-image: url(/demo/assets/images/list-2.png);
      }
    }

    li:nth-child(3) {
      &::before {
        background-image: url(/demo/assets/images/list-3.png);
      }
    }

    li:nth-child(4) {
      &::before {
        background-image: url(/demo/assets/images/list-4.png);
      }
    }

    li:nth-child(5) {
      &::before {
        background-image: url(/demo/assets/images/list-5.png);
      }
    }
    li:nth-child(6) {
      &::before {
        background-image: url(/demo/assets/images/list-6.png);
      }
    }
    li:nth-child(7) {
      &::before {
        background-image: url(/demo/assets/images/list-1.png);
      }
    }
  }
</style>
