<template>
  <div>
    <frames />
    <copy-stand />
    <controller />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import CopyStand from './CopyStand'
import { provideCopyStand } from '../compositions/useCopyStand'
import Frames from './Frames'
import Controller from './Controller.vue'

export default defineComponent({
  setup() {
    provideCopyStand()
  },
  components: {
    Frames,
    CopyStand,
    Controller
  }
})

</script>

<style lang="scss" scoped>

</style>
