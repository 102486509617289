<template>
  <div class="copy-stand">
    <div>
      <div class="message">
        <div :class="{ show: !isUserImageSelectable }"><div>上の一覧から撮影したいデザインを選んでください。</div></div>
        <div :class="{ show: isUserImageSelectable && !isControllable }"><div>下の操作パネルから合成する写真を選んでください。</div></div>
        <div :class="{ 'show-hide': isUserImageSelectable && isControllable }"><div>写真のドラッグや回転・ズームを使って<br>好きなデザインに調整してください。</div></div>
      </div>
      <div id="copy-stand-main"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import { injectCopyStand, useCopyStand } from '../compositions/useCopyStand'

export default defineComponent({
  setup () {
    const CopyStand = injectCopyStand()

    useCopyStand('copy-stand-main')

    const isUserImageSelectable = computed(() => {
      return (CopyStand.selectedFrame.label)
    })

    const isControllable = computed(() => {
      return (CopyStand.userImage)
    })

    return {
      CopyStand,
      isUserImageSelectable,
      isControllable
    }
  }
})
</script>

<style lang="scss" scoped>
$key-color: #ffffff;
$key-color-light: #696868;
$key-color-lighter: #ffffff;
$bg-dot-base-color: str-replace(#{$key-color-light}, '#', '');
$bg-dot-circle-color: str-replace(#{$key-color}, '#', '');

.copy-stand {
  width: 100%;
  margin: 2rem auto;
  overflow: hidden;
  max-width: 1000px;
  background: $key-color-lighter;
  // padding: 2rem;
  border-radius: 1rem;

  @media all and (max-width: 764px) {
    width: 100%;
    padding: 0;
    border-radius: .2rem;
    margin: 1rem 0;
  }

  > div {
    max-width: 720px;
    margin: 0 auto;
    position: relative;
  }
}

.message {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  pointer-events: none;

  > div > div {
    background: rgba(88, 88, 88, 0.7);
    padding: 2rem;
    margin: 0 2rem;
    border-radius: 1rem;

    @media all and (max-width: 767px) {
      padding: 1rem;
    }
  }

  > div {
    color: #ffffff;
    opacity: 0;
    transform: translateY(100%);
    transition: .3s;
    position: absolute;
    padding: 0 1rem;

    @media all and (max-width: 767px) {
      padding: 0;
    }

    &.show {
      opacity: 1;
      transform: translateY(0);
    }

    &.show-hide {
      opacity: 1;
      transform: translateY(0);
      animation: sh 0s ease-in 10s forwards;
    }
  }
}

@keyframes sh {
  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

#copy-stand-main {
  background: #ffffff;
}
</style>

<style lang="scss">
.konvajs-content {
  margin: 0 auto;
}
</style>
