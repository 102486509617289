export function useUA() {
  const ua = window.navigator.userAgent.toLowerCase()
  return {
    ua,
    isAndroid() {
      return !!ua.match(/Android ([.\d]+)/i)
    },
    isIOS() {
      return !!ua.match(/iPhone|iPad/i)
    },
    isOther() {
      return !ua.match(/Android ([.\d]+)/i) && !ua.match(/iPhone|iPad/i)
    }
  }
}
