import { provide, reactive, inject, onMounted, watch } from '@vue/composition-api'
import Konva from 'konva'

const exAreaRate = 1.16
const editAreaWidth = 1000
const editAreaHeight = 1000
const stageWidth = editAreaWidth * exAreaRate
const stageHeight = editAreaHeight * exAreaRate
const frameCornerColor = '#E40014'

const CopyStandKey = Symbol('CopyStand')
const CopyStand = reactive({
  initKonva: false,
  selectedFrame: {},
  userImage: '',
  angle: 0,
  zoom: 1,
  stamp: ''
})
const KonvaState = {}
const StageSetting = {
  container: '',
  width: stageWidth,
  height: stageHeight
}

export function provideCopyStand() {
  provide(CopyStandKey, CopyStand)
}

export function injectCopyStand() {
  return inject(CopyStandKey, CopyStand)
}

export function getTrimData() {
  if (!KonvaState.stage) {
    return ''
  }
  try {
    KonvaState.stage.draw()
    const margin = KonvaState.containerWidth * (exAreaRate - 1)
    const option = {
      mimeType: 'image/jpeg',
      x: margin / 2,
      y: margin / 2,
      width: KonvaState.containerWidth - margin,
      height: KonvaState.containerHeight - margin,
      pixelRatio: editAreaWidth / (KonvaState.containerWidth - margin),
      quality: 1
    }
    const selectednode = KonvaState.stampTr.nodes()
    KonvaState.stampTr.nodes([])
    const result = KonvaState.stage.toDataURL(option)
    KonvaState.stampTr.nodes(selectednode)
    return result
  } catch (e) {
    console.log(e)
  }
}

export function download() {
  const margin = editAreaWidth * (exAreaRate - 1)

  const data = KonvaState.stage.toDataURL({
    x: margin / 4,
    y: margin / 4,
    width: KonvaState.stage.width() - margin,
    height: KonvaState.stage.height() - margin,
    pixelRatio: editAreaWidth / (KonvaState.stage.width() - margin)
  })
  const a = document.createElement('a')
  const e = document.createEvent('MouseEvent')
  a.download = 'filename.jpg'
  a.href = data
  e.initEvent(
    'click',
    true,
    true,
    window,
    1,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null
  )
  a.dispatchEvent(e)
}

export function useCopyStand(stageId) {
  watch(() => CopyStand.selectedFrame, (selectedFrame) => {
    if (selectedFrame.frame) {
      setFrame(selectedFrame.frame)
    }
  })

  watch(() => CopyStand.stamp, (stamp) => {
    setStamp(stamp)
  })

  watch(() => CopyStand.userImage, (userImage) => {
    if (userImage) {
      Konva.Image.fromURL(userImage, function(image) {
        CopyStand.angle = 0
        CopyStand.zoom = 1
        image.draggable(true)

        const shortside = Math.min(image.width(), image.height())
        const scale = (shortside === image.width()) ? (stageWidth / shortside) : (stageHeight / shortside)

        image.width(image.width() * scale)
        image.height(image.height() * scale)
        image.x(stageWidth / 2)
        image.y(stageHeight / 2)
        image.offsetX(image.width() / 2)
        image.offsetY(image.height() / 2)

        image.dragBoundFunc(pos => {
          const xMin = ((image.width() / 2) - (stageWidth * 0.15)) * -1
          const xMax = (stageWidth * 0.85) + (image.width() / 2)
          const yMin = ((image.height() / 2) - (stageHeight * 0.15)) * -1
          const yMax = stageHeight * 0.85 + (image.height() / 2)

          if (pos.x <= xMin) { pos.x = xMin }
          if (pos.x >= xMax) { pos.x = xMax }
          if (pos.y <= yMin) { pos.y = yMin }
          if (pos.y >= yMax) { pos.y = yMax }
          return pos
        })
        image.on('dblclick', () => {
          image.x(stageWidth / 2)
          image.y(stageHeight / 2)
          KonvaState.userImage.draw()
        })

        image.on('mouseenter', () => {
          KonvaState.stage.container().style.cursor = 'pointer'
        })

        image.on('mouseleave', () => {
          KonvaState.stage.container().style.cursor = 'default'
        })

        KonvaState.userImage.destroyChildren()
        KonvaState.userImage.add(image)
        KonvaState.userImage.draw()
      })
    } else {
      KonvaState.userImage.destroyChildren()
      KonvaState.userImage.draw()
      CopyStand.angle = 0
      CopyStand.zoom = 1
    }
  })

  watch(() => CopyStand.zoom, (zoom) => {
    const img = KonvaState.userImage.findOne('Image')
    img.scale({ x: zoom, y: zoom })
    KonvaState.userImage.draw()
  })

  watch(() => CopyStand.angle, (angle) => {
    const img = KonvaState.userImage.findOne('Image')
    img.rotation(angle)
    KonvaState.userImage.draw()
  })

  onMounted(() => {
    StageSetting.container = stageId
    KonvaState.stage = new Konva.Stage(StageSetting)
    KonvaState.bg = new Konva.Layer()
    KonvaState.userImage = new Konva.Layer()
    KonvaState.frame = new Konva.Layer()
    KonvaState.stamp = new Konva.Layer()
    KonvaState.editFrame = new Konva.Layer()

    const margin = editAreaWidth * (exAreaRate - 1)

    const editFrame = new Konva.Rect({
      x: margin / 4,
      y: margin / 4,
      width: editAreaWidth + (margin / 2),
      height: editAreaHeight + (margin / 2),
      stroke: 'black',
      strokeWidth: margin / 2,
      opacity: 0.3,
      listening: false
    })
    KonvaState.editFrame.add(editFrame)

    const strokeWidthBase = Math.floor(margin / 25)
    const borderMin = Math.floor(margin / 2) - strokeWidthBase + 1
    const borderMax = margin * 1.4

    const editFrameBorderTL = new Konva.Line({
      points: [borderMin, borderMax + (margin / 2), borderMin, borderMin, borderMax + (margin / 2), borderMin],
      stroke: frameCornerColor,
      strokeWidth: strokeWidthBase * 2
    })
    KonvaState.editFrame.add(editFrameBorderTL)

    const editFrameBorderTR = new Konva.Line({
      points: [
        stageWidth - (margin / 2) - borderMax,
        (margin / 2) - strokeWidthBase,
        stageWidth - (margin / 2) + strokeWidthBase,
        (margin / 2) - strokeWidthBase,
        stageWidth - (margin / 2) + strokeWidthBase,
        borderMax + (margin / 2)
      ],
      stroke: frameCornerColor,
      strokeWidth: strokeWidthBase * 2
    })
    KonvaState.editFrame.add(editFrameBorderTR)

    const editFrameBorderBL = new Konva.Line({
      points: [
        borderMin,
        stageHeight - borderMax - (margin / 2),
        borderMin,
        stageHeight - (margin / 2) + strokeWidthBase,
        borderMax + (margin / 2),
        stageHeight - (margin / 2) + strokeWidthBase
      ],
      stroke: frameCornerColor,
      strokeWidth: strokeWidthBase * 2
    })
    KonvaState.editFrame.add(editFrameBorderBL)

    const editFrameBorderBR = new Konva.Line({
      points: [
        stageWidth - borderMax - (margin / 2),
        stageHeight - (margin / 2) + strokeWidthBase,
        stageWidth - (margin / 2) + strokeWidthBase,
        stageHeight - (margin / 2) + strokeWidthBase,
        stageWidth - (margin / 2) + strokeWidthBase,
        stageHeight - (margin / 2) - borderMax
      ],
      stroke: frameCornerColor,
      strokeWidth: strokeWidthBase * 2
    })
    KonvaState.editFrame.add(editFrameBorderBR)

    const bg = new Konva.Rect({
      x: 0,
      y: 0,
      width: stageWidth,
      height: stageHeight,
      fill: 'white',
      listening: false
    })
    KonvaState.bg.add(bg)

    const stampTr = new Konva.Transformer({
      keepRatio: true,
      enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
      borderStroke: 'AAA',
      anchorStroke: 'AAA',
      anchorCornerRadius: 15,
      anchorSize: 30,
      padding: 7,
      boundBoxFunc(oldBoundBox, newBoundBox) {
        const maxw = KonvaState.stage.width() / 1
        const maxh = KonvaState.stage.width() / 1

        newBoundBox.rotation = newBoundBox.rotation * 1
        if (newBoundBox.width < 20 || newBoundBox.height < 10) {
          stampTr.stopTransform()
          return oldBoundBox
        }

        if (newBoundBox.width > maxw || newBoundBox.height > maxh) {
          stampTr.stopTransform()
          return oldBoundBox
        }
        return newBoundBox
      }
    })

    const updateTransformer = stampTr.update
    stampTr.update = () => {
      updateTransformer()
      const rot = stampTr.findOne('.rotater')
      const rotImage = new Image()
      rotImage.onload = () => {
        rot.stroke('rgba(0,255,0,0)')
        rot.fillPatternImage(rotImage)
        rot.fillPriority('pattern')
        rot.fillPatternRepeat('no-repeat')
        rot.fillPatternOffset({
          x: 1,
          y: 0
        })
        rot.fillPatternScale({
          x: 0.32,
          y: 0.32
        })
      }
      rotImage.src = '/demo/assets/images/rotate.png'
      const tl = stampTr.findOne('.top-left')
      const tr = stampTr.findOne('.top-right')
      const br = stampTr.findOne('.bottom-right')
      const bl = stampTr.findOne('.bottom-left')
      const resImage = new Image()
      resImage.onload = () => {
        tl.stroke('rgba(0,255,0,0)')
        tl.fillPatternImage(resImage)
        tl.fillPriority('pattern')
        tl.fillPatternRepeat('no-repeat')
        tl.fillPatternOffset({
          x: 1,
          y: 0
        })
        tl.fillPatternScale({
          x: 0.32,
          y: 0.32
        })
        br.stroke('rgba(0,255,0,0)')
        br.fillPatternImage(resImage)
        br.fillPriority('pattern')
        br.fillPatternRepeat('no-repeat')
        br.fillPatternOffset({
          x: 1,
          y: 0
        })
        br.fillPatternScale({
          x: 0.32,
          y: 0.32
        })
        tr.stroke('rgba(0,255,0,0)')
        tr.fillPatternImage(resImage)
        tr.fillPriority('pattern')
        tr.fillPatternRepeat('no-repeat')
        tr.fillPatternRotation(90)
        tr.fillPatternOffset({
          x: 1,
          y: 31
        })
        tr.fillPatternScale({
          x: 0.32,
          y: 0.32
        })
        bl.stroke('rgba(0,255,0,0)')
        bl.fillPatternImage(resImage)
        bl.fillPriority('pattern')
        bl.fillPatternRepeat('no-repeat')
        bl.fillPatternRotation(90)
        bl.fillPatternOffset({
          x: 1,
          y: 31
        })
        bl.fillPatternScale({
          x: 0.32,
          y: 0.32
        })
      }
      resImage.src = '/demo/assets/images/resize.png'
    }
    stampTr.forceUpdate()
    KonvaState.stampTr = stampTr
    KonvaState.stamp.add(stampTr)
    const stampSelect = (e) => {
      if (e.target.attrs.image?.id === 'stamp') {
        stampTr.nodes([e.target])
        KonvaState.stamp.draw()
      } else {
        stampTr.nodes([])
        KonvaState.stamp.draw()
      }
    }
    KonvaState.stage.on('click tap', stampSelect)
    KonvaState.stage.on('dragstart', stampSelect)

    KonvaState.stage.add(KonvaState.bg)
    KonvaState.stage.add(KonvaState.userImage)
    KonvaState.stage.add(KonvaState.frame)
    KonvaState.stage.add(KonvaState.editFrame)
    KonvaState.stage.add(KonvaState.stamp)
    KonvaState.stage.draw()
    fitStageToCopyStand()
    CopyStand.initKonva = true
    window.addEventListener('resize', fitStageToCopyStand)
  })

  return {
    CopyStand
  }
}

function setFrame(path = null) {
  const imageEl = new Image()
  imageEl.onload = () => {
    const frame = new Konva.Image({
      x: (stageWidth - editAreaWidth) / 2,
      y: (stageHeight - editAreaHeight) / 2,
      image: imageEl,
      width: editAreaWidth,
      height: editAreaHeight,
      listening: false
    })

    KonvaState.frame.destroyChildren()
    KonvaState.frame.add(frame)
    KonvaState.frame.batchDraw()
  }
  imageEl.id = 'attraction-copy-stand-frame'
  imageEl.src = path
}

function setStamp(path = null) {
  if (path === '') {
    const oldStamp = KonvaState.stamp.findOne('Image')
    if (oldStamp) {
      oldStamp.destroy()
    }
    KonvaState.stampTr.nodes([])
    KonvaState.stamp.draw()
    return
  }
  const imageEl = new Image()
  imageEl.onload = () => {
    const stampNumber = path.match(/\/stamp-(\d+)\.png$/)[1]
    const wmax = stampNumber === '4' ? 450 : 300
    const rate = wmax / imageEl.width
    const w = imageEl.width * rate
    const h = imageEl.height * rate
    const stamp = new Konva.Image({
      image: imageEl,
      width: w,
      height: h,
      x: (stageWidth / 2) - wmax / 2,
      y: (stageHeight / 2) - h / 2,
      draggable: true
    })

    const oldStamp = KonvaState.stamp.findOne('Image')
    if (oldStamp) {
      oldStamp.destroy()
    }
    KonvaState.stamp.add(stamp)
    KonvaState.stampTr.nodes([stamp])
    KonvaState.stamp.draw()
  }
  imageEl.id = 'stamp'
  imageEl.src = path
}

function fitStageToCopyStand() {
  const container = document.querySelector('.copy-stand > div')
  const containerWidth = container.offsetWidth
  const scale = containerWidth / stageWidth

  KonvaState.containerWidth = containerWidth
  KonvaState.containerHeight = containerWidth
  KonvaState.stage.width(stageWidth * scale)
  KonvaState.stage.height(stageHeight * scale)
  KonvaState.stage.scale({ x: scale, y: scale })
  KonvaState.stage.draw()
}
